import styled from "react-emotion";

const ServiceSubtitle = styled.h3`
    font-weight: 600;
    font-size: 1.125em;
    line-height: 1.45;
    position: relative;
    display: inline-block;
    letter-spacing: -.013em;
    color: #fff;
    text-align: center;
    white-space: pre-line;

    @media screen and (min-width: 768px) {
        text-align: left;
    }
`;

export default ServiceSubtitle;
