import styled from "react-emotion";

const ServicesColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 3.4em;

    @media screen and (min-width: 768px){
        width: 37%;
        margin-bottom: 0;
        align-items: stretch;
    }

    @media screen and (min-width: 1024px) {
        width: calc(50% - 7em);
    }
`;

export default ServicesColumn;
