import styled from "react-emotion";

const ServicesPrice = styled.p`
    font-weight: normal;
    line-height: 1.6;
    font-size: 1.125em;
    letter-spacing: -0.013em;
    color: #D4B9A7;
    margin: 0;
    margin-top: 1.2em;

    @media screen and (min-width: 768px) {
        margin-top: 0.89em;
    }
`;

export default ServicesPrice;
