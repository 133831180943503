import styled from "react-emotion";

const DomainsTitle = styled.h2`
    font-weight: 500;
    line-height: 1.15;
    font-size: 1em;
    text-align: center;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #d4b9a7;
    margin-bottom: 3.4em;

    @media screen and (min-width: 768px){
        margin-bottom: 4em;
    }
`;

export default DomainsTitle;
