import styled from "react-emotion";

const ColumnWrap = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    padding-top: 8em;

    @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        padding: 0;
    }
    @media screen and (min-width: 1024px) {
        padding: 0 5em;
    }
    @media screen and (min-width: 1280px){
        padding: 0 6em;
    }
`;

export default ColumnWrap;
