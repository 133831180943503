import React from "react";
import PropTypes from "prop-types";

import ColumnWrap from "./ColumnWrap";
import ServicesColumn from "./ServicesColumn";
import ServiceSubtitle from "./ServiceSubtitle";
import ServicesPrice from "./ServicesPrice";
import Circle from "./Circle";
import Ship from "../assets/icons";

import { StyledIcon } from "@common/components";

const ServicesList = ( {servicesData} ) => {
    return (
        <ColumnWrap>
            <Circle>
                <StyledIcon width="40" height="40">
                    <Ship/>
                </StyledIcon>
            </Circle>
                {servicesData.map(item =>
                    <ServicesColumn key={item.id} >
                        <ServiceSubtitle>{item.service}</ServiceSubtitle>
                        <ServicesPrice>{item.price}</ServicesPrice>
                    </ServicesColumn>
                )}
        </ColumnWrap>
    );
};

export default ServicesList;

ServicesList.propTypes = {
    servicesData: PropTypes.array.isRequired
};
