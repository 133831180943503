import styled from "react-emotion";

const Circle = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(315deg, #bd947a 10.84%, #d4b9a7 88.54%);
    width: 5em;
    height: 5em;
    min-width: 5em;
    border-radius: 50%;
    margin-bottom: 0.8em;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);

    @media screen and (min-width: 768px) {
        margin-bottom: 0;
    }
`;

export default Circle;
