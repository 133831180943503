import React from "react";
import styled from "react-emotion";

import Container from "./Container";
import DomainsTitle from "./DomainsTitle";
import ServicesList from "./ServicesList";

const ServicesBlock = () => {

    const ServicesData = [
        {
            "id": "1",
            "service": "Переадресация домена с помощью 301-го редиректа",
            "price": "99 руб./год за 1 домен"
        },
        {
            "id": "2",
            "service": "Перенос домена .ru или .рф к нам на обслуживание",
            "price": "149 руб. за 1 домен"
        }
    ];

    return (
        <Services>
            <Container>
                <DomainsTitle>Дополнительные услуги</DomainsTitle>
                <ServicesList servicesData = { ServicesData } />
            </Container>
        </Services>
    );
};

export default ServicesBlock;

const Services = styled.section`
    font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (1600 - 320)));
    background-color: #02508b;
    width: 100%;
    position: relative;
    padding: 3.4em 0 0;

    @media screen and (min-width: 768px) {
        padding: 4em 0;
    }
`;
